import React, { useState } from "react";

function NewsModal() {
  const [modalstatus, setModalStatus] = useState(true);

  return modalstatus ? (
    <div
      className="bg-black bg-opacity-50 fixed top-0 w-full min-h-screen left-0 fixed flex flex-row items-center justify-center"
      style={{ "z-index": "50" }}
    >
      <div className="bg-white w-100 h-100 rounded py-5 flex px-5 flex flex-col items-center justify-center max-w-sm">
        <div className="w-full flex flex-row justify-end">
          <div
            className="text-xs bg-red-500 text-white py-1 px-2 rounded cursor-pointer"
            onClick={() => setModalStatus(false)}
          >
            Cerrar
          </div>
        </div>

        <div className="font-bold">Call For Papers</div>
        <div className="font-bold">Burden of Disease in South America</div>
        <div className="font-bold">Science Direct - Public Health</div>
        <div className="flex flex-col items-center px-4 text-center ">
          <a
            target={"_blank"}
            href="https://www.sciencedirect.com/journal/public-health/about/call-for-papers"
            rel="noreferrer"
            className="px-2 py-2 rounded bg-blue-200 my-5"
          >
            Ir al enlace
          </a>
          <div>
            Más información sobre los requisitos puede encontrarlo a
            continuación.
          </div>
          <a
            target={"_blank"}
            href="https://www.journals.elsevier.com/public-health"
            rel="noreferrer"
            className="px-2 py-2 rounded bg-blue-200"
          >
            Más información
          </a>
          <div className="mt-5">
            Todos los manuscritos deben ser enviados en línea a
          </div>
          <a
            target={"_blank"}
            href="https://www.editorialmanager.com/puhe/default2.aspx"
            rel="noreferrer"
            className="px-2 py-2 rounded bg-blue-200"
          >
            Ir a la página de envío
          </a>
          <div className="mt-5">
            Haciendo clic en el botón 'submit paper'. Los autores necesitarán
            tambien registrar sus detalles y pueden luego enviar su escrito.
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default NewsModal;
