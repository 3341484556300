import * as React from "react";
import { GlobalStyle } from "../GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import Container from "@components/Container/Container";
import { Link } from "gatsby";
import TwoStages from "@components/TwoStages/TwoStages";
import styled from "styled-components";
import RightNavBar from "@components/RightNavBar/RightNavBar";
import VideoContainer from "@components/VideoContainer/VideoContainer";

import adorno from "@images/home/adorno.png";
import NewsModal from "../components/NewsModal/NewsModal";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--tema4);
`;

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <title>QuEST LAC</title>
        <meta
          name="description"
          content="Red QuEST en Latinoamérica y el Caribe"
        />
        <meta
          name="keywords"
          content="QUEST,NETWORK,Latinoamerica,Caribe,PERU,UPCH,ARGENTINA,HARVARD"
        />
      </Helmet>
      <GlobalStyle />
      <div className="w-full relative">
        <Navbar />
        <Wrapper>
          <Container>
            <BackgroundImg></BackgroundImg>
            <TwoStages
              content={
                <SubWrapper>
                  <TextPlace>
                    <h1>
                      <b>
                        QuEST LAC: Generando Evidencia de Calidad para la
                        Transformación de los Sistemas de Salud en Latinoamérica
                        y el Caribe
                      </b>
                    </h1>
                    <Link to={"acerca/que-es"}>Conoce más</Link>
                  </TextPlace>
                </SubWrapper>
              }
              rightContent={<RightNavBar hero={true} />}
              bg={"#26578f"}
              dheight={"450px"}
            />
          </Container>
        </Wrapper>
        <Relleno />
        <VideoContainer />
        <Footer />
      </div>
    </>
  );
};

export default IndexPage;

const SubWrapper = styled.div``;

const BackgroundImg = styled.div`
  background-color: var(--color1);
  overflow: hidden;
  width: 100%;
  min-height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  height: 120%;
  @media only screen and (min-width: 768px) {
    min-height: 700px;
  }

  background-image: url(${adorno});
  background-size: cover;
  background-repeat: no-repeat;
`;

const TextPlace = styled.div`
  width: 95%;
  margin: auto;

  h1 {
    padding: 15px;
    background-color: #ffffffcc;
    font-size: 22px;
    color: var(--tema4);
  }

  @media only screen and (min-width: 768px) {
    position: absolute;
    top: 100px;
    right: 20px;
    width: 70%;
    min-width: 200px;
    max-width: 600px;
  }

  @media only screen and (min-width: 1024px) {
    h1 {
      font-size: 22px;
      color: var(--tema4);
    }
  }

  text-align: center;

  a {
    float: right;
    margin-top: 10px;
    width: 150px;
    display: block;
    color: white;
    padding: 5px;
    background-color: var(--color2);
  }
`;

const Relleno = styled.div`
  height: 150px;
  width: 100%;
  background-color: var(--color1);
`;
