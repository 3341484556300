import React from 'react';
import styled from "styled-components";
import { Link } from 'gatsby';

const Wrapper = styled.div`
background-color: var(--color1);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media only screen and (min-width: 768px){
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
`;

const VideoSection = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
padding: 20px;
@media only screen and (min-width: 768px){
width: 45%;
}
`



const VideoTitle = styled.div`
color: white;
font-weight: bold;
font-size: 20px;
text-align: center;
@media only screen and (min-width: 768px){
    font-size: 30px;
}
`

const VideoButton = styled(Link)`
text-align: center;
color: white;
font-weight: bold;
background-color: var(--color2);
padding: 10px;
margin: 10px auto;
color: white;
`


const VideoFrame = styled.div`
border: solid 15px gray;
background-color: black;
@media only screen and (min-width: 768px){
    height: 19vw;
    width:  32vw;
}
margin: 15px auto;
display: flex;
iframe{
    width: 100%;
}
`;




const VideoContainer = () => {
    return (
        <Wrapper>
            <VideoSection>
                <VideoFrame>
                <iframe src="https://www.youtube.com/embed/YpuQ26T5kVw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </VideoFrame>
                <VideoTitle>
                Principales objetivos QuEST LAC
                </VideoTitle>
                <VideoButton to={"/acerca/objetivos"} >
                    Conoce Más
                </VideoButton>
            </VideoSection>
            <VideoSection>
                <VideoFrame>
                <iframe  src="https://www.youtube.com/embed/2iJ8_SNdtcQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </VideoFrame>
                <VideoTitle>
                Saludos QuEST Global
                </VideoTitle>
            </VideoSection>
        </Wrapper>
    )
}

export default VideoContainer;